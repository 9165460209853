<template>
  <div class="packages">
    <h2 class="page-title">选择套装</h2>
    <b-card>
      <loading v-if="loading"/>
      <template v-else>
        <div class="products">
          <cell-link :to="`/packages/${item.packageNo}`"
                     head-width="10em"
                     v-for="item in validPackages"
                     :key="item.packageNo">
            <square :src="item.hero" height="75%" slot="head" style="min-width: 8rem;"/>
            <div class="pl-2">
              <h5 class="mb-1">
                {{item.title}}
                <b-badge variant="primary" v-if="item.badge">{{item.badge}}</b-badge>
              </h5>
              <div class="text-muted">{{item.shortDesc}}</div>
              <div>
                <span class="price">{{item.price}}</span>
                <del class="small text-muted" v-if="item.oriPrice">{{item.oriPrice}}</del>
              </div>
            </div>
          </cell-link>
        </div>
      </template>
    </b-card>
    <b-btn block variant="link" @click="$router.back()">返回</b-btn>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'packages',
  mixins: [routeData('packages')],
  computed: {
    validPackages() {
      return this.packages.filter(i => !i.disabled)
    }
  },
  methods: {
    onLoad() {
      if (this.validPackages.length === 1) {
        return this.$router.replace('/packages/' + this.validPackages[0].packageNo)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.packages {
  max-width: 640px;

  .cell {
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: transparent;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .price {
    font-size: 1.25em;
    color: $primary;
  }
}
</style>
