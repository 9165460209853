<template>
  <div class="package-buy">
    <b-card title="套装信息">
      <b-row align-v="center">
        <b-col cols="4">
          <ske type="square" height="75%" v-if="loading"/>
          <square :src="product.hero" height="75%" v-else/>
        </b-col>
        <b-col>
          <h4>
            <ske v-if="loading" width="10em"/>
            <template v-else>{{product.title}}</template>
          </h4>
          <ske v-if="loading" width="10em"/>
          <div class="text-muted" v-else>{{product.shortDesc}}</div>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="expired">
      <h5>很抱歉，当前套装已下架哦</h5>
      <div class="mb-3">试试其他套装吧</div>

      <b-link @click="$router.go(-1)">返回</b-link>
    </b-card>

    <template v-else-if="!loading">
      <b-card title="价格详情">
        <b-row v-if="oriPrice">
          <b-col>原价</b-col>
          <b-col cols="auto">
            <del>{{$rmb(oriPrice)}}</del>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b>现价</b>
          </b-col>
          <b-col cols="auto">
            <ske width="4em" v-if="loading"/>
            <span v-else>{{$rmb(unitPrice)}}</span>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col>
            <b>数量</b>
          </b-col>
          <b-col cols="auto">
            <stepper v-model="order.count" style="max-width: 10em;"/>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b>总价</b>
          </b-col>
          <b-col cols="auto" class="text-primary">
            <ske width="4em" v-if="loading"/>
            <span v-else>{{$rmb(totalPrice)}}</span>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="isGiftcards">
        <b-card-title>
          收件信息
          <b-link class="small float-right" @click="showSplitter = !showSplitter">
            自动识别 (Beta)
            <fa icon="caret-up" v-if="showSplitter"/>
            <fa icon="caret-down" v-else/>
          </b-link>
        </b-card-title>

        <b-form-group v-if="showSplitter">
          <address-splitter @change="onDetect"/>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="收件人">
              <b-input id="consignee" maxlength="20" placeholder="收件人姓名" v-ls
                       v-model="order.consignee"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="手机号">
              <b-input id="phone" maxlength="11" placeholder="收件人手机号" type="tel" v-ls
                       v-model="order.phone"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="地址信息">
          <address-picker cache v-model="order.address" ref="addressPicker"/>
        </b-form-group>

        <b-link @click="showNote = true" v-if="!showNote">
          <fa icon="plus-circle"/>
          添加留言
        </b-link>

        <b-form-group class="mb-0" v-else>
          <template slot="label">
            订单留言
            <b-link class="float-right" @click="order.note = ''; showNote = false">删除</b-link>
          </template>
          <textarea class="form-control" id="note" maxlength="100" name="note"
                    placeholder="是给客服看的留言哦" v-autofocus
                    v-autosize v-model="order.note" rows="1"></textarea>
        </b-form-group>
      </b-card>

      <b-card v-if="allowBalance" title="余额">
        <p>
          当前余额
          <ske width="4em" v-if="loading"/>
          <b v-else>{{$rmb(userBalance)}}</b>
          <b-link @click.stop="goRecharge">充值更优惠</b-link>
        </p>
        <cell head-width="3em" title="余额支付" v-model="useBalance"
              type="checkbox" :disabled="!userBalance">
          <div slot="head">
            <img class="icon" src="@/assets/images/icons/balance.svg">
          </div>
          <template slot="body">使用余额抵扣 {{$rmb(Math.min(userBalance, totalPrice))}}</template>
        </cell>
      </b-card>

      <b-card v-if="need2Pay" title="支付方式">
        <cell head-width="3em" title="微信支付" v-model="payType" val="wxpay">
          <img class="icon" slot="head" src="../assets/images/icons/wxpay.svg">
        </cell>

        <cell head-width="3em" title="支付宝支付" v-if="!isMiniProgram" v-model="payType"
              val="alipay">
          <img class="icon" slot="head" src="../assets/images/icons/alipay.svg">
        </cell>

        <cell head-width="3em" title="请好友代付" v-model="payType" val="wxpay_web_friend">
          <img class="icon" slot="head"
               src="https://img.xinshu.me/upload/00bf4542454f4be184488ba809bb12eb">
        </cell>
      </b-card>

      <div class="btn-area">
        <b-btn :disabled="creating" block @click="submit" variant="primary">
          <fa fw icon="credit-card"/>
          <template v-if="creating">
            正在创建订单，请稍后...
          </template>
          <template v-else-if="useBalance">
            <span v-if="shortage">支付剩余 {{$rmb(shortage)}}</span>
            <span v-else>直接下单</span>
          </template>
          <template v-else-if="!need2Pay">
            无需支付，直接下单
          </template>
          <template v-else>
            支付 {{$rmb(totalPrice)}}
          </template>
        </b-btn>
      </div>
    </template>
  </div>
</template>

<script>
import route from '@/mixins/route-data'
import { round } from 'lodash'

export default {
  name: 'packageBuy',
  components: {
    Stepper: require('@/components/Stepper').default,
    AddressPicker: require('@/components/AddressPicker').default,
    AddressSplitter: require('@/components/AddressSplitter').default
  },
  mixins: [route('packages'), route('package')],
  data() {
    return {
      payType: 'wxpay',
      userBalance: 0,
      creating: false,
      useBalance: false,
      showSplitter: false,
      showNote: false,
      order: {
        count: 1,
        consignee: '',
        phone: '',
        address: '',
        note: ''
      }
    }
  },
  computed: {
    isGiftcards() {
      return this.package.category === 'physical'
    },
    unitPrice() {
      const price = this.package.price
      return round(price, 2)
    },
    totalPrice() {
      return this.unitPrice * this.order.count
    },
    oriPrice() {
      return this.product?.oriPrice?.replace?.(/\D/g, '') || 0
    },
    product() {
      return this.packages.find(i => i.packageNo === this.$route.params.id)
    },
    need2Pay() {
      return this.shortage > 0
    },
    shortage() {
      if (!this.allowBalance || !this.useBalance) {
        return this.totalPrice
      }
      return round(Math.max(0, this.totalPrice - this.userBalance), 2)
    },
    expired() {
      return new Date(this.package.endTime) < new Date()
    },
    allowBalance() {
      if (this.isPartnerUser) {
        return false
      }
      return !this.package.extra?.disableBalance
    },
    errors() {
      if (!this.isGiftcards) {
        return []
      }
      const errors = []
      const {consignee, phone, address, count} = this.order

      if (!consignee) {
        errors.push({
          message: '请补全收货人信息',
          type: 'consignee'
        })
      }

      if (/test|测试|^\d+$/.test(consignee)) {
        errors.push({
          message: '请填写正确的收货人信息',
          type: 'consignee'
        })
      }

      if (!phone) {
        errors.push({
          message: '请补全手机号',
          type: 'phone'
        })
      }

      if (!/1[3456789]\d{9}/.test(phone) && !/(1-)?\d{3}-?\d{7,}/.test(phone)) {
        errors.push({
          message: '手机号格式错误',
          type: 'phone'
        })
      }

      if (!address || address.split('|').filter(Boolean).length < 4) {
        errors.push({
          message: '请补全地址信息',
          type: 'address'
        })
      }

      if (!count) {
        errors.push({
          message: '请选择数量',
          type: 'count'
        })
      }

      return errors
    }
  },
  methods: {
    onLoad() {
      this.useBalance = false
      if (this.allowBalance) {
        this.$ajax.fetchBalance().then(data => {
          this.userBalance = data.balance
          if (this.userBalance > 0) {
            this.useBalance = true
          }
        })
      }
    },
    onDetect({consignee, phone, address}) {
      this.order.consignee = consignee
      this.order.phone = phone
      this.order.address = address
      if (!address) {
        this.$refs.addressPicker.reset()
      }
    },
    async submit() {
      try {
        if (this.errors.length) {
          const error = this.errors[0]
          this.$alert.error(error.message)
          if (!window.isMobile) {
            const errorEl = this.$el.querySelector('#' + error.type)
            if (errorEl) {
              errorEl.scrollIntoView()
              errorEl.focus()
            }
          }
          return
        }

        this.creating = true

        const shortage = this.shortage
        const data = {
          count: this.order.count,
          packageNo: this.$route.params.id,
          payType: this.useBalance ? 'balance' : this.payType
        }
        if (this.isGiftcards) {
          data.consignee = this.order.consignee
          data.phone = this.order.phone
          data.addr = this.order.address
        }
        const result = await this.$req.post('/api/order/packages/orders', data)

        const orderNo = result.id || result.orderId || result.orderNo

        this.$alert.success('下单成功，请继续支付')

        if (this.useBalance && shortage) {
          const rechargeOrder = await this.$req.post('/api/gift_card/order/create', {
            cardId: 0,
            payType: this.payType,
            price: shortage,
            relateOrderNo: orderNo
          })
          return this.$router.push('/pay/' + rechargeOrder.orderNo)
        }

        this.$router.push('/pay/' + orderNo)
      } catch (err) {
        console.error(err)
        this.creating = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.package-buy {
  max-width: 560px;
}
</style>
