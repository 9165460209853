<template>
  <div class="package">
    <loading v-if="loading"/>
    <template v-else-if="!product">
      <b-card>
        <empty emotion="cry">对不起，没有相关套装哦</empty>
      </b-card>
      <b-btn block variant="link">返回</b-btn>
    </template>

    <template v-else>
      <b-card class="card-intro" no-body>
        <swiper :options="swiperOptions" style="width: 100%;" ref="swiper">
          <swiper-slide v-if="product.video">
            <video-player class="4:3" :src="product.video" :autoplay="!isAndroid"
                          :poster="product.videoCover || product.carousel[0]"
                          @play="onVideoPlay" @ended="onVideoEnd"/>
          </swiper-slide>
          <swiper-slide v-for="item in product.carousel" :key="item">
            <square height="75%" :src="item"/>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <b-card-body>
          <h4 class="title">{{product.title}}</h4>
          <div class="text-muted">{{product.desc}}</div>
          <div class="tags clearfix">
            <div class="tag float-left mr-2" v-for="tag in product.tags" :key="tag">
              <fa icon="check-circle" far class="text-success"/>
              {{tag}}
            </div>
          </div>

          <div v-if="product.price">
            <span class="price">{{product.price.replace(/\s起$/, '')}}</span>
            <small v-if="product.price.includes('起')" class="text-muted">起</small>
            <del class="text-muted ml-2" v-if="product.oriPrice">{{product.oriPrice}}</del>
          </div>

          <div class="mt-3">
            <b-btn class="mt-3" variant="primary" block disabled v-if="expired">
              套装已下架
            </b-btn>
            <b-btn class="mt-3" variant="primary" block disabled v-else-if="notStart">
              套装未开售
            </b-btn>
            <b-row id="btnBuy"  v-else>
              <b-col cols="auto" v-if="!isPartnerUser">
                <b-btn block to="/books?bookType=albums" v-if="!$store.state.prevRoute">
                  <fa icon="home"/>
                </b-btn>
                <b-btn block @click="$router.go(-1)" v-else>
                  <fa icon="chevron-left"/>
                </b-btn>
              </b-col>
              <b-col>
                <b-btn block variant="primary" :to="`/packages/${$route.params.id}/buy`">
                  下单购买
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>

      <b-card v-if="product.details.length">
        <b-card-title>套装内容（共 {{product.details.length}} 项）</b-card-title>
        <b-row>
          <b-col md="6" v-for="item in product.details" :key="item.tid">
            <b-row align-v="center" class="my-2">
              <b-col cols="auto" style="width: 100px;" class="pr-0">
                <square :src="item.pic" height="75%"/>
              </b-col>
              <b-col>
                <h5>{{item.title}} × {{item.count}}</h5>
                <div>
                  <span class="text-primary">{{item.price}}</span>
                  <del class="small text-muted"
                       v-if="item.oriPrice && item.oriPrice !== item.price">{{item.oriPrice}}</del>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="套装介绍" v-if="product.presentations && product.presentations.length">
        <div class="presentations">
          <b-link :href="img.link" :disabled="!img.link" v-for="img in product.presentations"
                  :key="img.pic">
            <img :src="img.pic" :alt="img.title">
          </b-link>
        </div>
      </b-card>

      <bottom-bar sm target="#btnBuy" v-if="!notStart && !expired">
        <div class="content p-3 mx-auto" style="max-width: 640px;">
          <b-row>
            <b-col cols="auto" v-if="!isPartnerUser">
              <b-btn block to="/books?bookType=albums" v-if="!$store.state.prevRoute">
                <fa icon="home"/>
              </b-btn>
              <b-btn block @click="$router.go(-1)" v-else>
                <fa icon="chevron-left"/>
              </b-btn>
            </b-col>
            <b-col>
              <b-btn block variant="primary" :to="`/packages/${$route.params.id}/buy`">下单购买</b-btn>
            </b-col>
          </b-row>
        </div>
      </bottom-bar>
    </template>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

export default {
  name: 'package',
  mixins: [
    route('packages'),
    route('package'),
    require('@/mixins/swiper').default
  ],
  data() {
    return {
      showFooter: false,
      swiperOptions: {
        autoplay: {
          delay: 6000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    product() {
      return this.packages.find(i => i.packageNo === this.$route.params.id)
    },
    expired() {
      return new Date(this.package.endTime) < new Date()
    },
    notStart() {
      return new Date(this.package.startTime) > new Date()
    }
  }
}
</script>

<style scoped lang="scss">
.package {
  max-width: 640px;
}

.tags {
  padding-top: .5em;
  padding-bottom: .5em;
  white-space: nowrap;
  color: $text-muted;
}

.price {
  font-size: 1.5em;
  color: $primary;
}

.cell-link {
  padding-left: 0;
  padding-right: 0;
}

.sticky {
  position: sticky;
  bottom: 20px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid $hr-border-color;

  .content {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
